import React, { ReactNode } from 'react';
import './MainContainer.css';
import DarkHeader from '../Header/Header';
import DarkFooter from '../Footer/Footer';

type MainContainerProps = {
  children: ReactNode;
};

const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
  return (
    <>
      <DarkHeader title='Venchass Software' />
      <div className="main-container">{children}</div>
      <DarkFooter  />
    </>
  )
};

export default MainContainer;
