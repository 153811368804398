import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainContainer from './components/Main/main';
import { LoginPage } from './pages/Login/Login';
import { RegistrationPage } from './pages/SignIn/SignIn';
import { Provider } from 'react-redux';
import { store } from './reducer/store';
import { ProfilePage } from './pages/Dashboard/profile';
import { ThemeProvider, createTheme } from '@mui/material';
import { Home } from './pages/Home/home';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme({
  // Your theme configuration
});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainContainer ><Home /></MainContainer>} />
            <Route path="/login" element={<MainContainer ><LoginPage /></MainContainer>} />
            <Route path="/signup" element={<MainContainer ><RegistrationPage /></MainContainer>} />
            <Route path="/profile" element={<MainContainer ><ProfilePage /></MainContainer>} />
            <Route path="*" element={<MainContainer ><Home /></MainContainer>} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
