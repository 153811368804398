import React from 'react';
import './DarkFooter.css';
import { Link, styled } from '@mui/material';

const StyledLink = styled(Link)({
  textDecoration: 'none', // Удаление подчеркивания
  color: 'white', // Изменение цвета ссылки
});

const DarkFooter: React.FC = () => {
  return (
    <footer className="dark-footer">
      <StyledLink href="https://discord.gg/3D9SVyBkjA">Discord</StyledLink>
    </footer>
  );
};

export default DarkFooter;
