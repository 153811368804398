import axios, { getJWT } from "./api";

export interface ISubscription {
  id: number;
  end_date: string;
  start_date: string;
  subscription_name: string;
  user_id: string;
}

export const getSubs = (user_id: string) => {
  const jwt = getJWT();
  return axios.get<ISubscription[]>(`/subscriptions/`, {
    headers: {
      Authorization: jwt
    }
  });
}

export const activateKey = (key: string) => {
  const jwt = getJWT();
  return axios.post("/activate-key", {
    key
  }, 
  {
    headers: {
      Authorization: jwt
    }
  })
}