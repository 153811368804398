import React, { useState } from 'react';
import "./loginpage.css";
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { loginUser } from '../../api/userSign';
import { useDispatch } from 'react-redux';
import { signIn, updateJwt, updateLogin, updatePerms } from '../../reducer/profileSlice';
import { useNavigate } from 'react-router-dom';

interface LoginFormState {
  username: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const [formState, setFormState] = useState<LoginFormState>({
    username: '',
    password: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSignIn = () => {
    dispatch(signIn());
  };
  const handleUpdateLogin = (newLogin: string) => {
    dispatch(updateLogin(newLogin));
  };
  const handleUpdatePerms = (perms: number) => {
    dispatch(updatePerms(perms));
  };
  const handleUpdateJwt = (newJwt: string) => {
    dispatch(updateJwt(newJwt));
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    // TODO: Handle login logic here
    loginUser(formState.username, formState.password)
    .then(res => {
      handleSignIn();
      handleUpdateLogin(formState.username);
      handleUpdateJwt(res.token);
      handleUpdatePerms(res.permissions)
      navigate("/profile")
    })
    .catch(err => {
      console.log(err);
    })
      
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Login
        </Typography>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <TextField
            type="text"
            name="username"
            label="Username"
            variant="outlined"
            value={formState.username}
            onChange={handleInputChange}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            variant="outlined"
            value={formState.password}
            onChange={handleInputChange}
          />
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
