import { Button } from "@mui/material";
import "./home.css"

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100vh',
    background: 'radial-gradient(circle, #ff8800, #ff5500)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    fontSize: '48px',
    color: '#000000',
  },
});

const YourComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h1 className={classes.title}>Venchass Panel</h1>
      </div>
    </div>
  );
};

export const Home = () => {
  return (
    <>
    <main>
      <YourComponent />


    </main>
      
    </>
  );
};
