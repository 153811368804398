import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer/store';
import { useNavigate } from 'react-router-dom';
import { SubscriptionPanel } from '../../components/moderator/subs/subs';
import { createSub } from '../../api/moderator';
import { SubscriptionCard } from '../../components/profile/subCard/SubscriptionCard';
import { ISubscription, getSubs } from '../../api/profile';
import axios from ".././../api/api";
import { SubscriptionForm } from '../../components/profile/keySubForm/SubscriptionForm';



export const ProfilePage = () => {
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.profile);
  const username = profile.login;
  const [subscriptions, setsubscriptions] = useState<ISubscription[]>([]);
  useEffect(() => {
    if(!profile.isSignedIn) {
      navigate("/");
    }
    getSubs(profile.login || "")
    .then(response => {
      setsubscriptions(response.data);
    })
    .catch(error => {
      // Обработка ошибок
      console.error(error);
      // Дальнейшая обработка ошибки, например, вывод сообщения об ошибке
    });
  }, [navigate, profile])
  const downloadClick = (fileName: string) => {
    axios
      .get(`/download/${fileName}`, {
        responseType: 'blob', // Set the response type to blob
      })
      .then((response) => {
        // Create a download link for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName + ".rar");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error: any) => {
        console.error(error);
        // Handle error
      });
  }
  
  return (
    <Box>
      
      <Typography variant="h5" component="h3" gutterBottom>
        {username}
      </Typography>
      <Box sx={{ marginTop: '1rem' }}>
        <Typography variant="h6" component="h4" gutterBottom>
          Subscriptions:
        </Typography>
        {subscriptions.map((subscription) => (
          <SubscriptionCard key={subscription.id} subscriptionName={subscription.subscription_name} endDate={subscription.end_date} onDownload={() => {downloadClick(subscription.subscription_name)}} />
        ))}
        <SubscriptionForm />
        {profile.permissions === 1 ? 
          <SubscriptionPanel onIssueSubscription={(data) => { createSub(data); }} />
           : null}
      </Box>
    </Box>
  );
};
