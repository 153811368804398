import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import { generateKey } from '../../../api/moderator';

export interface SubscriptionFormState {
  username: string;
  subscriptionName: string;
  startDate: string;
  endDate: string;
}

interface SubscriptionPanelProps {
  onIssueSubscription: (subscription: SubscriptionFormState) => void;
}

export const SubscriptionPanel: React.FC<SubscriptionPanelProps> = ({ onIssueSubscription }) => {
  const [formState, setFormState] = React.useState<SubscriptionFormState>({
    username: '',
    subscriptionName: '',
    startDate: '',
    endDate: '',
  });
  const [keys, setkeys] = useState<string[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handelSelectChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, subscriptionName: value }));
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onIssueSubscription(formState);
    setFormState({
      username: '',
      subscriptionName: '',
      startDate: '',
      endDate: '',
    });
  };

  const getenerateKeyClick = () => {
    generateKey()
    .then(res => {
      keys.push(res.data.keyValue)
      setkeys([...keys]);
    })
    .catch(err => {
      console.log(err);
    })
  }

  return (
    <Box>
      <Typography variant="h6" component="h3" gutterBottom>
        Subscription
      </Typography>
      <Box component="form" onSubmit={handleFormSubmit}>
        <TextField
          type="text"
          name="username"
          label="Username"
          variant="outlined"
          value={formState.username}
          onChange={handleInputChange}
          sx={{ marginBottom: '1rem' }}
        />
        <div>
          <TextField
            type="text"
            name="subscriptionName"
            label="Subscription Name"
            variant="outlined"
            value={formState.subscriptionName}
            onChange={handleInputChange}
            sx={{ marginBottom: '1rem' }}
          />

          <FormControl variant="outlined" sx={{ marginBottom: '1rem' }}>
            <InputLabel id="select-label">Options</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              name="selectedOption"
              value={formState.subscriptionName}
              onChange={handelSelectChange}
              label="Options"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="DM boost">DM boost</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TextField
          type="date"
          name="startDate"
          variant="outlined"
          value={formState.startDate}
          onChange={handleInputChange}
          sx={{ marginBottom: '1rem' }}
        />
        <TextField
          type="date"
          name="endDate"
          variant="outlined"
          value={formState.endDate}
          onChange={handleInputChange}
          sx={{ marginBottom: '1rem' }}
        />
        <Button size="large" type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
      <div>
      <Button variant="contained" color="primary" onClick={getenerateKeyClick}>
        Generate Key
      </Button>
      <List>
        {keys.map((key, index) => (
          <ListItem key={index}>
            <ListItemText primary={`Generated Key ${index + 1}: ${key}`} />
          </ListItem>
        ))}
      </List>
    </div>
    </Box>
  );
};

