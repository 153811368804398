// profileSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
  isSignedIn: boolean;
  login: string | null;
  jwt: string | null;
  permissions: number;
}

const initialState: ProfileState = {
  isSignedIn: false,
  login: "username",
  jwt: null,
  permissions: 0
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    signIn: (state) => {
      state.isSignedIn = true;
    },
    signOut: (state) => {
      state.isSignedIn = false;
      state.login = null;
      state.jwt = null;
    },
    updateLogin: (state, action: PayloadAction<string>) => {
      state.login = action.payload;
    },
    updateJwt: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    updatePerms: (state, action: PayloadAction<number>) => {
      state.permissions = action.payload;
    },
  },
});

export const { signIn, signOut, updateLogin, updateJwt, updatePerms } = profileSlice.actions;

export default profileSlice.reducer;
