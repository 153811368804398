import axios from "./api";

export const registerUser = async (username: string, password: string, email: string): Promise<ILogin> => {
  try {
    const response = await axios.post<ILogin>('/register', {
      username,
      password,
      email,
    });
    
    return response.data; // Success message from the server
  } catch (error) {
    throw new Error(error as string); // Error message from the server
  }
};
interface ILogin {
  token: string,
  permissions: number
}
export const loginUser = async (username: string, password: string): Promise<ILogin> => {
  try {
    const response = await axios.post<ILogin>('/login', {
      username,
      password,
    });

    return response.data; // JWT token from the server
  } catch (error) {
    throw new Error(error as string); // Error message from the server
  }
};