import React from 'react';
import './DarkHeader.css';
import { Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducer/store';
import { signOut } from '../../reducer/profileSlice';

type DarkHeaderProps = {
  title: string;
};

const DarkHeader: React.FC<DarkHeaderProps> = ({ title }) => {
  const profile = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(signOut());
  }
  const nav = useNavigate();
  return (
    <header className="dark-header">
      <div onClick={() => nav("/")}>
        <h1>{title}</h1>
      </div>
      <div className='Headerbutton'>
        {profile.isSignedIn ? 
        <>
        <Typography onClick={() => nav("/profile")} variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {profile.login}
        </Typography>
        <Button color="inherit" onClick={onLogout}>logout</Button>
        </>
         : 
      <>
      <Link to={"/login"}>
          <Button variant="outlined">Login</Button>
        </Link>
        <Link to={"/signup"}>
          <Button variant="outlined">Sign in</Button>
        </Link>
      </>}
        
      </div>
    </header>
  );
};

export default DarkHeader;
