import axios, { getJWT } from "../api/api";
import { SubscriptionFormState } from "../components/moderator/subs/subs";

export const createSub = (formState: SubscriptionFormState) => {
  const jwt = getJWT();
  return axios
      .post('/subscriptions/create', {
        user_id: formState.username, // Assuming the username is used as the user_id
        subscription_name: formState.subscriptionName,
        start_date: formState.startDate,
        end_date: formState.endDate,
      }, { headers: {
        Authorization: jwt
      } })
      .then((response) => {
        // Handle the response if needed
        console.log(response.data); // For example, log the success message
      })
      .catch((error) => {
        // Handle the error if needed
        console.error(error);
      });
}

interface IgenerateKey {
  keyValue: string
}

export const generateKey = () => {
  const jwt = getJWT();

  return axios
    .post<IgenerateKey>("/generate-key", {
      subscription_name: "DM boost"
    },
    {
      headers: {
        Authorization: jwt
      }
    });
}