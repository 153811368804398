import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { activateKey } from '../../../api/profile';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15px",
    gap: "5px",
    display: 'flex',
    alignItems: 'center',
  },
}));

export const SubscriptionForm = () => {
  const classes = useStyles();
  const [activationKey, setActivationKey] = useState('');
  const [error, setError] = useState('');


  const handleActivationKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivationKey(event.target.value);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Здесь можно добавить логику для обработки ключа активации
    activateKey(activationKey)
    .then(res => {
        setError("Подписка активированна")
    })
    .catch(err => {
        setError(err.message)
    })

  };

  return (
    <form onSubmit={handleFormSubmit} className={classes.root}>
      <TextField
        label="Your key"
        variant="outlined"
        value={activationKey}
        onChange={handleActivationKeyChange}
      />
      <Button type="submit" variant="contained" color="primary">
        Activate key
      </Button>
      {error}
    </form>
  );
};
