import React from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

interface SubscriptionCardProps {
  subscriptionName: string;
  endDate: string;
  onDownload: () => void;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ subscriptionName, endDate, onDownload }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="h3" gutterBottom>
          {subscriptionName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          End Date: {endDate}
        </Typography>
        <Button variant="outlined" onClick={onDownload} sx={{ marginTop: '1rem' }}>
          Download
        </Button>
      </CardContent>
    </Card>
  );
};
